import React from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { Top2, Text } from "src/components/styled"
import { SIZES } from "src/layout/constants"

const Section = styled.section`
  margin: ${SIZES.mobileMargin}px;
  @media (min-width: 800px) {
    max-width: ${SIZES.maxWidth}px;
    margin: ${SIZES.margin}px auto ${SIZES.margin * 2}px;
    padding: 0 ${SIZES.margin * 2}px;
  }
`

const ConfirmationPage: GatsbyPage = () => {
  return (
    <Layout title="confirmation">
      <Top2>
        <FormattedMessage id="confirmation.title" tagName="h1" />
      </Top2>
      <Section>
        <FormattedMessage id="confirmation.text" tagName={Text} />
      </Section>
    </Layout>
  )
}

export default ConfirmationPage
